import { ref } from 'vue';
import { ElMessage } from 'element-plus/lib/components';
export default (cb)=>{
  // 划词出菜单
  const popShow = ref(false)
  const popLeft = ref(0)
  const popTop = ref(0)
  let selectedStr;
  const showPop = (e) => {
    selectedStr = window.getSelection()?.toString() || ''
    if(!selectedStr) return;
    popLeft.value = e.clientX
    popTop.value = e.clientY
    popShow.value = true
  }
  //复制
  const handleCopy = ()=>{
    const input = document.createElement('input')
    document.body.appendChild(input)
    input.setAttribute('value',selectedStr)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
    ElMessage.success('复制成功')
    popShow.value = false
  }
  //搜索
  const handleSearch = ()=>{
    popShow.value = false
    cb(selectedStr)
  }
  return {
    popShow,
    popLeft,
    popTop,
    showPop,
    handleCopy,
    handleSearch
  }
}