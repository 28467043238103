import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5e4608b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "breadcrumb"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.matched.meta.title)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_router_link, {
            style: _normalizeStyle(`color:${_ctx.color};`),
            to: { name: 'Home' }
          }, {
            default: _withCtx(() => [
              _createTextVNode(" 首页 > ")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["style"]),
          _createElementVNode("span", {
            class: "title",
            style: _normalizeStyle(`color:${_ctx.color};`)
          }, _toDisplayString(_ctx.matched.meta.title), 5 /* TEXT, STYLE */)
        ]),
        _createCommentVNode(" <div v-if=\"matched.meta.id == 2\">\n      <router-link :to=\"{ name: 'Home' }\"\n        >&nbsp;首页&nbsp;>&nbsp;</router-link\n      ><span class=\"title\">{{ matched.meta.title }}</span>\n    </div> ")
      ]))
    : _createCommentVNode("v-if", true)
}