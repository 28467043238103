
import { defineComponent, watch, ref } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  props: {
    color: {
      type: String,
      default: '#449fe6'
    },
  },
  setup() {
    const route = useRoute()
    const matched = ref()
    route.matched.forEach((item) => {
      matched.value = item
    })

    watch(
      () => route.matched,
      (val) => {
        val.forEach((item) => {
          matched.value = item
        })
      }
    )
    return { matched }
  }
})
